/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import { fetcher } from "../../client/client";
import type {
  DirectAccessErrorResponse,
  InternalServerErrorResponse,
  InvestmentConfirmPaymentBody,
  InvestmentGetFeesSuccessResponse,
  InvestmentGetSuccessResponse,
  InvestmentMaterialsDownloadSuccessResponse,
  InvestmentUpdateBody,
  InvestmentUpdateSuccessResponse,
  InvestmentsConfirmPaymentParams,
  InvestmentsConfirmPaymentSuccessResponse,
  InvestmentsGetFeesParams,
  InvestmentsMaterialsDownloadParams,
  NotAuthorisedResponse,
  NotFoundResponse,
} from "../schemas";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * Soft-deletes the account's investment into the specified deal.

 */
export const investmentsDelete = (dealUnident: string) => {
  return fetcher<void>({
    url: `/v3/investments/${dealUnident}`,
    method: "DELETE",
  });
};

export const getInvestmentsDeleteMutationOptions = <
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof investmentsDelete>>,
    TError,
    { dealUnident: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof investmentsDelete>>,
  TError,
  { dealUnident: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof investmentsDelete>>,
    { dealUnident: string }
  > = (props) => {
    const { dealUnident } = props ?? {};

    return investmentsDelete(dealUnident);
  };

  return { mutationFn, ...mutationOptions };
};

export type InvestmentsDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof investmentsDelete>>
>;

export type InvestmentsDeleteMutationError =
  | NotAuthorisedResponse
  | NotFoundResponse
  | InternalServerErrorResponse;

export const useInvestmentsDelete = <
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof investmentsDelete>>,
    TError,
    { dealUnident: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof investmentsDelete>>,
  TError,
  { dealUnident: string },
  TContext
> => {
  const mutationOptions = getInvestmentsDeleteMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Looks up an investment into the specified deal by the authenticated
account. This data includes all the information regarding the account's
investment into the deal such as the commitment, the payment reference,
the amount already paid and more.

 */
export const investmentsGet = (dealUnident: string, signal?: AbortSignal) => {
  return fetcher<InvestmentGetSuccessResponse>({
    url: `/v3/investments/${dealUnident}`,
    method: "GET",
    signal,
  });
};

export const getInvestmentsGetQueryKey = (dealUnident: string) => {
  return [`/v3/investments/${dealUnident}`] as const;
};

export const getInvestmentsGetQueryOptions = <
  TData = Awaited<ReturnType<typeof investmentsGet>>,
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(
  dealUnident: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof investmentsGet>>, TError, TData>
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getInvestmentsGetQueryKey(dealUnident);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof investmentsGet>>> = ({
    signal,
  }) => investmentsGet(dealUnident, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!dealUnident,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof investmentsGet>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type InvestmentsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof investmentsGet>>
>;
export type InvestmentsGetQueryError =
  | NotAuthorisedResponse
  | NotFoundResponse
  | InternalServerErrorResponse;

export function useInvestmentsGet<
  TData = Awaited<ReturnType<typeof investmentsGet>>,
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(
  dealUnident: string,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof investmentsGet>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof investmentsGet>>,
          TError,
          TData
        >,
        "initialData"
      >;
  }
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useInvestmentsGet<
  TData = Awaited<ReturnType<typeof investmentsGet>>,
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(
  dealUnident: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof investmentsGet>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof investmentsGet>>,
          TError,
          TData
        >,
        "initialData"
      >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useInvestmentsGet<
  TData = Awaited<ReturnType<typeof investmentsGet>>,
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(
  dealUnident: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof investmentsGet>>, TError, TData>
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useInvestmentsGet<
  TData = Awaited<ReturnType<typeof investmentsGet>>,
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(
  dealUnident: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof investmentsGet>>, TError, TData>
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getInvestmentsGetQueryOptions(dealUnident, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Updates an investment for an open deal the account has been invited to.
NOTE: The backend uses the requesting account ID and the deal slug to 
look up the relevant investment data.

 */
export const investmentsUpdate = (
  dealUnident: string,
  investmentUpdateBody: InvestmentUpdateBody
) => {
  return fetcher<InvestmentUpdateSuccessResponse>({
    url: `/v3/investments/${dealUnident}`,
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    data: investmentUpdateBody,
  });
};

export const getInvestmentsUpdateMutationOptions = <
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof investmentsUpdate>>,
    TError,
    { dealUnident: string; data: InvestmentUpdateBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof investmentsUpdate>>,
  TError,
  { dealUnident: string; data: InvestmentUpdateBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof investmentsUpdate>>,
    { dealUnident: string; data: InvestmentUpdateBody }
  > = (props) => {
    const { dealUnident, data } = props ?? {};

    return investmentsUpdate(dealUnident, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type InvestmentsUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof investmentsUpdate>>
>;
export type InvestmentsUpdateMutationBody = InvestmentUpdateBody;
export type InvestmentsUpdateMutationError =
  | NotAuthorisedResponse
  | NotFoundResponse
  | InternalServerErrorResponse;

export const useInvestmentsUpdate = <
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof investmentsUpdate>>,
    TError,
    { dealUnident: string; data: InvestmentUpdateBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof investmentsUpdate>>,
  TError,
  { dealUnident: string; data: InvestmentUpdateBody },
  TContext
> => {
  const mutationOptions = getInvestmentsUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Calculates fees for the investment given a commitment amount.
 */
export const investmentsGetFees = (
  dealUnident: string,
  params?: InvestmentsGetFeesParams,
  signal?: AbortSignal
) => {
  return fetcher<InvestmentGetFeesSuccessResponse>({
    url: `/v3/investments/${dealUnident}/fees`,
    method: "GET",
    params,
    signal,
  });
};

export const getInvestmentsGetFeesQueryKey = (
  dealUnident: string,
  params?: InvestmentsGetFeesParams
) => {
  return [
    `/v3/investments/${dealUnident}/fees`,
    ...(params ? [params] : []),
  ] as const;
};

export const getInvestmentsGetFeesQueryOptions = <
  TData = Awaited<ReturnType<typeof investmentsGetFees>>,
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(
  dealUnident: string,
  params?: InvestmentsGetFeesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof investmentsGetFees>>,
        TError,
        TData
      >
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getInvestmentsGetFeesQueryKey(dealUnident, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof investmentsGetFees>>
  > = ({ signal }) => investmentsGetFees(dealUnident, params, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!dealUnident,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof investmentsGetFees>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type InvestmentsGetFeesQueryResult = NonNullable<
  Awaited<ReturnType<typeof investmentsGetFees>>
>;
export type InvestmentsGetFeesQueryError =
  | NotAuthorisedResponse
  | NotFoundResponse
  | InternalServerErrorResponse;

export function useInvestmentsGetFees<
  TData = Awaited<ReturnType<typeof investmentsGetFees>>,
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(
  dealUnident: string,
  params: undefined | InvestmentsGetFeesParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof investmentsGetFees>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof investmentsGetFees>>,
          TError,
          TData
        >,
        "initialData"
      >;
  }
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useInvestmentsGetFees<
  TData = Awaited<ReturnType<typeof investmentsGetFees>>,
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(
  dealUnident: string,
  params?: InvestmentsGetFeesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof investmentsGetFees>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof investmentsGetFees>>,
          TError,
          TData
        >,
        "initialData"
      >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useInvestmentsGetFees<
  TData = Awaited<ReturnType<typeof investmentsGetFees>>,
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(
  dealUnident: string,
  params?: InvestmentsGetFeesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof investmentsGetFees>>,
        TError,
        TData
      >
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useInvestmentsGetFees<
  TData = Awaited<ReturnType<typeof investmentsGetFees>>,
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(
  dealUnident: string,
  params?: InvestmentsGetFeesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof investmentsGetFees>>,
        TError,
        TData
      >
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getInvestmentsGetFeesQueryOptions(
    dealUnident,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * File download endpoint for relevant documents for the investment.
 */
export const investmentsMaterialsDownload = (
  dealUnident: string,
  filename:
    | "default-deal-sheet"
    | "deal-sheet"
    | "certificate"
    | "investor-list"
    | "eis-certificate"
    | "seis-certificate",
  params?: InvestmentsMaterialsDownloadParams,
  signal?: AbortSignal
) => {
  return fetcher<InvestmentMaterialsDownloadSuccessResponse>({
    url: `/v3/investments/${dealUnident}/materials/${filename}`,
    method: "GET",
    params,
    signal,
  });
};

export const getInvestmentsMaterialsDownloadQueryKey = (
  dealUnident: string,
  filename:
    | "default-deal-sheet"
    | "deal-sheet"
    | "certificate"
    | "investor-list"
    | "eis-certificate"
    | "seis-certificate",
  params?: InvestmentsMaterialsDownloadParams
) => {
  return [
    `/v3/investments/${dealUnident}/materials/${filename}`,
    ...(params ? [params] : []),
  ] as const;
};

export const getInvestmentsMaterialsDownloadQueryOptions = <
  TData = Awaited<ReturnType<typeof investmentsMaterialsDownload>>,
  TError = DirectAccessErrorResponse,
>(
  dealUnident: string,
  filename:
    | "default-deal-sheet"
    | "deal-sheet"
    | "certificate"
    | "investor-list"
    | "eis-certificate"
    | "seis-certificate",
  params?: InvestmentsMaterialsDownloadParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof investmentsMaterialsDownload>>,
        TError,
        TData
      >
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getInvestmentsMaterialsDownloadQueryKey(dealUnident, filename, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof investmentsMaterialsDownload>>
  > = ({ signal }) =>
    investmentsMaterialsDownload(dealUnident, filename, params, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(dealUnident && filename),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof investmentsMaterialsDownload>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type InvestmentsMaterialsDownloadQueryResult = NonNullable<
  Awaited<ReturnType<typeof investmentsMaterialsDownload>>
>;
export type InvestmentsMaterialsDownloadQueryError = DirectAccessErrorResponse;

export function useInvestmentsMaterialsDownload<
  TData = Awaited<ReturnType<typeof investmentsMaterialsDownload>>,
  TError = DirectAccessErrorResponse,
>(
  dealUnident: string,
  filename:
    | "default-deal-sheet"
    | "deal-sheet"
    | "certificate"
    | "investor-list"
    | "eis-certificate"
    | "seis-certificate",
  params: undefined | InvestmentsMaterialsDownloadParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof investmentsMaterialsDownload>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof investmentsMaterialsDownload>>,
          TError,
          TData
        >,
        "initialData"
      >;
  }
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useInvestmentsMaterialsDownload<
  TData = Awaited<ReturnType<typeof investmentsMaterialsDownload>>,
  TError = DirectAccessErrorResponse,
>(
  dealUnident: string,
  filename:
    | "default-deal-sheet"
    | "deal-sheet"
    | "certificate"
    | "investor-list"
    | "eis-certificate"
    | "seis-certificate",
  params?: InvestmentsMaterialsDownloadParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof investmentsMaterialsDownload>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof investmentsMaterialsDownload>>,
          TError,
          TData
        >,
        "initialData"
      >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useInvestmentsMaterialsDownload<
  TData = Awaited<ReturnType<typeof investmentsMaterialsDownload>>,
  TError = DirectAccessErrorResponse,
>(
  dealUnident: string,
  filename:
    | "default-deal-sheet"
    | "deal-sheet"
    | "certificate"
    | "investor-list"
    | "eis-certificate"
    | "seis-certificate",
  params?: InvestmentsMaterialsDownloadParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof investmentsMaterialsDownload>>,
        TError,
        TData
      >
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useInvestmentsMaterialsDownload<
  TData = Awaited<ReturnType<typeof investmentsMaterialsDownload>>,
  TError = DirectAccessErrorResponse,
>(
  dealUnident: string,
  filename:
    | "default-deal-sheet"
    | "deal-sheet"
    | "certificate"
    | "investor-list"
    | "eis-certificate"
    | "seis-certificate",
  params?: InvestmentsMaterialsDownloadParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof investmentsMaterialsDownload>>,
        TError,
        TData
      >
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getInvestmentsMaterialsDownloadQueryOptions(
    dealUnident,
    filename,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Record a payment confirmation for an investment.
 */
export const investmentsConfirmPayment = (
  dealUnident: string,
  investmentConfirmPaymentBody: InvestmentConfirmPaymentBody,
  params?: InvestmentsConfirmPaymentParams,
  signal?: AbortSignal
) => {
  return fetcher<InvestmentsConfirmPaymentSuccessResponse>({
    url: `/v3/investments/${dealUnident}/payments`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: investmentConfirmPaymentBody,
    params,
    signal,
  });
};

export const getInvestmentsConfirmPaymentMutationOptions = <
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof investmentsConfirmPayment>>,
    TError,
    {
      dealUnident: string;
      data: InvestmentConfirmPaymentBody;
      params?: InvestmentsConfirmPaymentParams;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof investmentsConfirmPayment>>,
  TError,
  {
    dealUnident: string;
    data: InvestmentConfirmPaymentBody;
    params?: InvestmentsConfirmPaymentParams;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof investmentsConfirmPayment>>,
    {
      dealUnident: string;
      data: InvestmentConfirmPaymentBody;
      params?: InvestmentsConfirmPaymentParams;
    }
  > = (props) => {
    const { dealUnident, data, params } = props ?? {};

    return investmentsConfirmPayment(dealUnident, data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type InvestmentsConfirmPaymentMutationResult = NonNullable<
  Awaited<ReturnType<typeof investmentsConfirmPayment>>
>;
export type InvestmentsConfirmPaymentMutationBody =
  InvestmentConfirmPaymentBody;
export type InvestmentsConfirmPaymentMutationError =
  | NotAuthorisedResponse
  | NotFoundResponse
  | InternalServerErrorResponse;

export const useInvestmentsConfirmPayment = <
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof investmentsConfirmPayment>>,
    TError,
    {
      dealUnident: string;
      data: InvestmentConfirmPaymentBody;
      params?: InvestmentsConfirmPaymentParams;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof investmentsConfirmPayment>>,
  TError,
  {
    dealUnident: string;
    data: InvestmentConfirmPaymentBody;
    params?: InvestmentsConfirmPaymentParams;
  },
  TContext
> => {
  const mutationOptions = getInvestmentsConfirmPaymentMutationOptions(options);

  return useMutation(mutationOptions);
};
