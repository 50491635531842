/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import { fetcher } from "../../client/client";
import type {
  BadRequestResponse,
  DealsDocumentDownloadParams,
  DealsDocumentDownloadSuccessResponse,
  DealsEventsPublishBody,
  DealsGetInfoSuccessResponse,
  DealsGetLogoSuccessResponse,
  DealsGetPublicInfoSuccessResponse,
  DealsMessagesCreateBody,
  DealsMessagesCreateSuccessResponse,
  DealsUpdateInfoBody,
  DealsUploadLogoBody,
  DirectAccessErrorResponse,
  InternalServerErrorResponse,
  NotAuthorisedResponse,
  NotFoundResponse,
} from "../schemas";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * Returns the 500x500 PNG logo of the investee company.
 */
export const dealsGetLogo = (dealId: string, signal?: AbortSignal) => {
  return fetcher<DealsGetLogoSuccessResponse>({
    url: `/v3/deals/${dealId}/logo`,
    method: "GET",
    signal,
  });
};

export const getDealsGetLogoQueryKey = (dealId: string) => {
  return [`/v3/deals/${dealId}/logo`] as const;
};

export const getDealsGetLogoQueryOptions = <
  TData = Awaited<ReturnType<typeof dealsGetLogo>>,
  TError = DirectAccessErrorResponse,
>(
  dealId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof dealsGetLogo>>, TError, TData>
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getDealsGetLogoQueryKey(dealId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof dealsGetLogo>>> = ({
    signal,
  }) => dealsGetLogo(dealId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!dealId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof dealsGetLogo>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type DealsGetLogoQueryResult = NonNullable<
  Awaited<ReturnType<typeof dealsGetLogo>>
>;
export type DealsGetLogoQueryError = DirectAccessErrorResponse;

export function useDealsGetLogo<
  TData = Awaited<ReturnType<typeof dealsGetLogo>>,
  TError = DirectAccessErrorResponse,
>(
  dealId: string,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof dealsGetLogo>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof dealsGetLogo>>,
          TError,
          TData
        >,
        "initialData"
      >;
  }
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useDealsGetLogo<
  TData = Awaited<ReturnType<typeof dealsGetLogo>>,
  TError = DirectAccessErrorResponse,
>(
  dealId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof dealsGetLogo>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof dealsGetLogo>>,
          TError,
          TData
        >,
        "initialData"
      >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useDealsGetLogo<
  TData = Awaited<ReturnType<typeof dealsGetLogo>>,
  TError = DirectAccessErrorResponse,
>(
  dealId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof dealsGetLogo>>, TError, TData>
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useDealsGetLogo<
  TData = Awaited<ReturnType<typeof dealsGetLogo>>,
  TError = DirectAccessErrorResponse,
>(
  dealId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof dealsGetLogo>>, TError, TData>
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getDealsGetLogoQueryOptions(dealId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Upload a logo for a deal, admins and syndicate leads only.
 */
export const dealsUploadLogo = (
  dealId: string,
  dealsUploadLogoBody: DealsUploadLogoBody
) => {
  return fetcher<void>({
    url: `/v3/deals/${dealId}/logo`,
    method: "PUT",
    headers: { "Content-Type": "application/octet-stream" },
    data: dealsUploadLogoBody,
  });
};

export const getDealsUploadLogoMutationOptions = <
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof dealsUploadLogo>>,
    TError,
    { dealId: string; data: DealsUploadLogoBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof dealsUploadLogo>>,
  TError,
  { dealId: string; data: DealsUploadLogoBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof dealsUploadLogo>>,
    { dealId: string; data: DealsUploadLogoBody }
  > = (props) => {
    const { dealId, data } = props ?? {};

    return dealsUploadLogo(dealId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type DealsUploadLogoMutationResult = NonNullable<
  Awaited<ReturnType<typeof dealsUploadLogo>>
>;
export type DealsUploadLogoMutationBody = DealsUploadLogoBody;
export type DealsUploadLogoMutationError =
  | NotAuthorisedResponse
  | NotFoundResponse
  | InternalServerErrorResponse;

export const useDealsUploadLogo = <
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof dealsUploadLogo>>,
    TError,
    { dealId: string; data: DealsUploadLogoBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof dealsUploadLogo>>,
  TError,
  { dealId: string; data: DealsUploadLogoBody },
  TContext
> => {
  const mutationOptions = getDealsUploadLogoMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Returns all information for a deal. This is only available to dealmakers
(for syndicate deals) and founders (for founder deals).

 */
export const dealsGetInfo = (dealUnident: string, signal?: AbortSignal) => {
  return fetcher<DealsGetInfoSuccessResponse>({
    url: `/v3/deals/${dealUnident}`,
    method: "GET",
    signal,
  });
};

export const getDealsGetInfoQueryKey = (dealUnident: string) => {
  return [`/v3/deals/${dealUnident}`] as const;
};

export const getDealsGetInfoQueryOptions = <
  TData = Awaited<ReturnType<typeof dealsGetInfo>>,
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(
  dealUnident: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof dealsGetInfo>>, TError, TData>
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getDealsGetInfoQueryKey(dealUnident);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof dealsGetInfo>>> = ({
    signal,
  }) => dealsGetInfo(dealUnident, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!dealUnident,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof dealsGetInfo>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type DealsGetInfoQueryResult = NonNullable<
  Awaited<ReturnType<typeof dealsGetInfo>>
>;
export type DealsGetInfoQueryError =
  | NotAuthorisedResponse
  | NotFoundResponse
  | InternalServerErrorResponse;

export function useDealsGetInfo<
  TData = Awaited<ReturnType<typeof dealsGetInfo>>,
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(
  dealUnident: string,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof dealsGetInfo>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof dealsGetInfo>>,
          TError,
          TData
        >,
        "initialData"
      >;
  }
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useDealsGetInfo<
  TData = Awaited<ReturnType<typeof dealsGetInfo>>,
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(
  dealUnident: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof dealsGetInfo>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof dealsGetInfo>>,
          TError,
          TData
        >,
        "initialData"
      >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useDealsGetInfo<
  TData = Awaited<ReturnType<typeof dealsGetInfo>>,
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(
  dealUnident: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof dealsGetInfo>>, TError, TData>
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useDealsGetInfo<
  TData = Awaited<ReturnType<typeof dealsGetInfo>>,
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(
  dealUnident: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof dealsGetInfo>>, TError, TData>
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getDealsGetInfoQueryOptions(dealUnident, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Update a deal.

This is only available to dealmakers (for syndicate deals) and
founders (for founder deals).

 */
export const dealsUpdateInfo = (
  dealUnident: string,
  dealsUpdateInfoBody: DealsUpdateInfoBody
) => {
  return fetcher<DealsGetInfoSuccessResponse>({
    url: `/v3/deals/${dealUnident}`,
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    data: dealsUpdateInfoBody,
  });
};

export const getDealsUpdateInfoMutationOptions = <
  TError =
    | BadRequestResponse
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof dealsUpdateInfo>>,
    TError,
    { dealUnident: string; data: DealsUpdateInfoBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof dealsUpdateInfo>>,
  TError,
  { dealUnident: string; data: DealsUpdateInfoBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof dealsUpdateInfo>>,
    { dealUnident: string; data: DealsUpdateInfoBody }
  > = (props) => {
    const { dealUnident, data } = props ?? {};

    return dealsUpdateInfo(dealUnident, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type DealsUpdateInfoMutationResult = NonNullable<
  Awaited<ReturnType<typeof dealsUpdateInfo>>
>;
export type DealsUpdateInfoMutationBody = DealsUpdateInfoBody;
export type DealsUpdateInfoMutationError =
  | BadRequestResponse
  | NotAuthorisedResponse
  | NotFoundResponse
  | InternalServerErrorResponse;

export const useDealsUpdateInfo = <
  TError =
    | BadRequestResponse
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof dealsUpdateInfo>>,
    TError,
    { dealUnident: string; data: DealsUpdateInfoBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof dealsUpdateInfo>>,
  TError,
  { dealUnident: string; data: DealsUpdateInfoBody },
  TContext
> => {
  const mutationOptions = getDealsUpdateInfoMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Download a document for the specified deal.

 */
export const dealsDocumentDownload = (
  dealUnident: string,
  params: DealsDocumentDownloadParams,
  signal?: AbortSignal
) => {
  return fetcher<DealsDocumentDownloadSuccessResponse>({
    url: `/v3/deals/${dealUnident}/documents`,
    method: "GET",
    params,
    signal,
  });
};

export const getDealsDocumentDownloadQueryKey = (
  dealUnident: string,
  params: DealsDocumentDownloadParams
) => {
  return [
    `/v3/deals/${dealUnident}/documents`,
    ...(params ? [params] : []),
  ] as const;
};

export const getDealsDocumentDownloadQueryOptions = <
  TData = Awaited<ReturnType<typeof dealsDocumentDownload>>,
  TError = NotAuthorisedResponse | NotFoundResponse | DirectAccessErrorResponse,
>(
  dealUnident: string,
  params: DealsDocumentDownloadParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof dealsDocumentDownload>>,
        TError,
        TData
      >
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getDealsDocumentDownloadQueryKey(dealUnident, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof dealsDocumentDownload>>
  > = ({ signal }) => dealsDocumentDownload(dealUnident, params, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!dealUnident,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof dealsDocumentDownload>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type DealsDocumentDownloadQueryResult = NonNullable<
  Awaited<ReturnType<typeof dealsDocumentDownload>>
>;
export type DealsDocumentDownloadQueryError =
  | NotAuthorisedResponse
  | NotFoundResponse
  | DirectAccessErrorResponse;

export function useDealsDocumentDownload<
  TData = Awaited<ReturnType<typeof dealsDocumentDownload>>,
  TError = NotAuthorisedResponse | NotFoundResponse | DirectAccessErrorResponse,
>(
  dealUnident: string,
  params: DealsDocumentDownloadParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof dealsDocumentDownload>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof dealsDocumentDownload>>,
          TError,
          TData
        >,
        "initialData"
      >;
  }
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useDealsDocumentDownload<
  TData = Awaited<ReturnType<typeof dealsDocumentDownload>>,
  TError = NotAuthorisedResponse | NotFoundResponse | DirectAccessErrorResponse,
>(
  dealUnident: string,
  params: DealsDocumentDownloadParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof dealsDocumentDownload>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof dealsDocumentDownload>>,
          TError,
          TData
        >,
        "initialData"
      >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useDealsDocumentDownload<
  TData = Awaited<ReturnType<typeof dealsDocumentDownload>>,
  TError = NotAuthorisedResponse | NotFoundResponse | DirectAccessErrorResponse,
>(
  dealUnident: string,
  params: DealsDocumentDownloadParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof dealsDocumentDownload>>,
        TError,
        TData
      >
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useDealsDocumentDownload<
  TData = Awaited<ReturnType<typeof dealsDocumentDownload>>,
  TError = NotAuthorisedResponse | NotFoundResponse | DirectAccessErrorResponse,
>(
  dealUnident: string,
  params: DealsDocumentDownloadParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof dealsDocumentDownload>>,
        TError,
        TData
      >
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getDealsDocumentDownloadQueryOptions(
    dealUnident,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Executes a deal event command.
 */
export const dealsEventsPublish = (
  dealUnident: string,
  dealsEventsPublishBody: DealsEventsPublishBody,
  signal?: AbortSignal
) => {
  return fetcher<void>({
    url: `/v3/deals/${dealUnident}/events`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: dealsEventsPublishBody,
    signal,
  });
};

export const getDealsEventsPublishMutationOptions = <
  TError =
    | BadRequestResponse
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof dealsEventsPublish>>,
    TError,
    { dealUnident: string; data: DealsEventsPublishBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof dealsEventsPublish>>,
  TError,
  { dealUnident: string; data: DealsEventsPublishBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof dealsEventsPublish>>,
    { dealUnident: string; data: DealsEventsPublishBody }
  > = (props) => {
    const { dealUnident, data } = props ?? {};

    return dealsEventsPublish(dealUnident, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type DealsEventsPublishMutationResult = NonNullable<
  Awaited<ReturnType<typeof dealsEventsPublish>>
>;
export type DealsEventsPublishMutationBody = DealsEventsPublishBody;
export type DealsEventsPublishMutationError =
  | BadRequestResponse
  | NotAuthorisedResponse
  | NotFoundResponse
  | InternalServerErrorResponse;

export const useDealsEventsPublish = <
  TError =
    | BadRequestResponse
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof dealsEventsPublish>>,
    TError,
    { dealUnident: string; data: DealsEventsPublishBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof dealsEventsPublish>>,
  TError,
  { dealUnident: string; data: DealsEventsPublishBody },
  TContext
> => {
  const mutationOptions = getDealsEventsPublishMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Sends a message to the recipients specified in the body payload. These
recipients MUST be investors, leads or founders of the deal specified by
`deal_unident` otherwise the request will fail with a permission error.

Calling this endpoint will send N emails for each recipient. Each email
will also include the sender via CC. The `Reply-To` header will be set
to the email address of the sender, so that a conversation can continue.

 */
export const dealsMessagesCreate = (
  dealUnident: string,
  dealsMessagesCreateBody: DealsMessagesCreateBody,
  signal?: AbortSignal
) => {
  return fetcher<DealsMessagesCreateSuccessResponse>({
    url: `/v3/deals/${dealUnident}/messages`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: dealsMessagesCreateBody,
    signal,
  });
};

export const getDealsMessagesCreateMutationOptions = <
  TError =
    | BadRequestResponse
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof dealsMessagesCreate>>,
    TError,
    { dealUnident: string; data: DealsMessagesCreateBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof dealsMessagesCreate>>,
  TError,
  { dealUnident: string; data: DealsMessagesCreateBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof dealsMessagesCreate>>,
    { dealUnident: string; data: DealsMessagesCreateBody }
  > = (props) => {
    const { dealUnident, data } = props ?? {};

    return dealsMessagesCreate(dealUnident, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type DealsMessagesCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof dealsMessagesCreate>>
>;
export type DealsMessagesCreateMutationBody = DealsMessagesCreateBody;
export type DealsMessagesCreateMutationError =
  | BadRequestResponse
  | NotAuthorisedResponse
  | NotFoundResponse
  | InternalServerErrorResponse;

export const useDealsMessagesCreate = <
  TError =
    | BadRequestResponse
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof dealsMessagesCreate>>,
    TError,
    { dealUnident: string; data: DealsMessagesCreateBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof dealsMessagesCreate>>,
  TError,
  { dealUnident: string; data: DealsMessagesCreateBody },
  TContext
> => {
  const mutationOptions = getDealsMessagesCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Returns only the public information for a deal.
Deprecated: use /v3/deals/{deal_unident} for dealmakers or
/v3/investments/{deal_unident} for investors instead.

 */
export const dealsGetPublicInfo = (
  dealUnident: string,
  signal?: AbortSignal
) => {
  return fetcher<DealsGetPublicInfoSuccessResponse>({
    url: `/v3/deals/${dealUnident}/public`,
    method: "GET",
    signal,
  });
};

export const getDealsGetPublicInfoQueryKey = (dealUnident: string) => {
  return [`/v3/deals/${dealUnident}/public`] as const;
};

export const getDealsGetPublicInfoQueryOptions = <
  TData = Awaited<ReturnType<typeof dealsGetPublicInfo>>,
  TError = NotFoundResponse | InternalServerErrorResponse,
>(
  dealUnident: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof dealsGetPublicInfo>>,
        TError,
        TData
      >
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getDealsGetPublicInfoQueryKey(dealUnident);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof dealsGetPublicInfo>>
  > = ({ signal }) => dealsGetPublicInfo(dealUnident, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!dealUnident,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof dealsGetPublicInfo>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type DealsGetPublicInfoQueryResult = NonNullable<
  Awaited<ReturnType<typeof dealsGetPublicInfo>>
>;
export type DealsGetPublicInfoQueryError =
  | NotFoundResponse
  | InternalServerErrorResponse;

export function useDealsGetPublicInfo<
  TData = Awaited<ReturnType<typeof dealsGetPublicInfo>>,
  TError = NotFoundResponse | InternalServerErrorResponse,
>(
  dealUnident: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof dealsGetPublicInfo>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof dealsGetPublicInfo>>,
          TError,
          TData
        >,
        "initialData"
      >;
  }
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useDealsGetPublicInfo<
  TData = Awaited<ReturnType<typeof dealsGetPublicInfo>>,
  TError = NotFoundResponse | InternalServerErrorResponse,
>(
  dealUnident: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof dealsGetPublicInfo>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof dealsGetPublicInfo>>,
          TError,
          TData
        >,
        "initialData"
      >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useDealsGetPublicInfo<
  TData = Awaited<ReturnType<typeof dealsGetPublicInfo>>,
  TError = NotFoundResponse | InternalServerErrorResponse,
>(
  dealUnident: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof dealsGetPublicInfo>>,
        TError,
        TData
      >
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useDealsGetPublicInfo<
  TData = Awaited<ReturnType<typeof dealsGetPublicInfo>>,
  TError = NotFoundResponse | InternalServerErrorResponse,
>(
  dealUnident: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof dealsGetPublicInfo>>,
        TError,
        TData
      >
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getDealsGetPublicInfoQueryOptions(dealUnident, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}
